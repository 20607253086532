import { QueryParams, SafeQueryParams } from '../types/url'

export const isAbsoluteRedirectToNonWillhabenDomain = (target: string): boolean => {
    try {
        // since ie 11 can create valid urls for relative paths by appending the current domain we have to check before creating urls
        if (target.startsWith('/')) {
            return false
        }
        const url = new URL(target)
        return !isWillhabenHost(url)
    } catch (error) {
        // the target is not a full URL, so it won't lead to another page
        return false
    }
}

export const isWillhabenHost = (url: URL) => {
    return url.host.endsWith('.willhaben.at')
}

// returns the first string from the given query parameter or headers
export const getFirstString = (queryParameter?: string | string[]): string | undefined => {
    if (typeof queryParameter === 'undefined') {
        return undefined
    } else if (typeof queryParameter === 'string') {
        return queryParameter
    } else if (queryParameter instanceof Array) {
        return queryParameter[0]
    } else {
        return undefined
    }
}

export const getFirstInt = (queryParameter?: string | string[]): number | undefined => {
    const firstString = getFirstString(queryParameter)
    if (typeof firstString === 'undefined') {
        return undefined
    } else {
        const firstInt = parseInt(firstString, 10)
        return isNaN(firstInt) ? undefined : firstInt
    }
}

export const getFirstBoolean = (queryParameter?: string | string[]): boolean | undefined => {
    const firstString = getFirstString(queryParameter)
    if (typeof firstString === 'undefined') {
        return undefined
    } else {
        return firstString === 'true'
    }
}

export const stripIsLogoutParameter = (url: string) => {
    return url.replace(/islogout=true&/, '').replace(/[?&]islogout=true/, '')
}

// adapted from: https://github.com/nygardk/react-share/blob/master/src/utils/objectToGetParams.js
export const objectToGetParams = (object: QueryParams) => {
    return `?${Object.keys(object)
        .filter((key) => !!object[key])
        .map((key) => {
            const value = object[key]
            const array = typeof value === 'string' ? [value] : value
            return array!.map((innerValue) => `${encodeURIComponent(key)}=${encodeURIComponent(innerValue)}`).join('&')
        })
        .join('&')}`
}

export const prependHttpIfMissing = (url: string, secure = false): string => {
    return /^https?:\/\//i.test(url) ? url : `http${secure ? 's' : ''}://${url}`
}

export const removeProtocol = (url: string): string => {
    return url.replace(/(^\w+:|^)\/\//, '')
}

export const getQueryParam = (url: string, param: string): string | string[] | undefined => {
    const params = getQueryParams(url)

    if (param in params) {
        return params[param]
    }
    return undefined
}

export const getQueryParams = (url: string) => {
    if (url.indexOf('?') === -1) {
        return {}
    }

    const hashes = url.slice(url.indexOf('?') + 1).split('&')
    const params: SafeQueryParams = {}

    hashes.forEach((hash) => {
        const [key, val] = hash.split('=') as [string, string | undefined]

        if (val) {
            const value = decodeUriComponentSafely(val)
            const existingValue = params[decodeUriComponentSafely(key)]
            if (typeof existingValue === 'undefined') {
                params[decodeUriComponentSafely(key)] = value
            } else if (typeof existingValue === 'string') {
                params[decodeUriComponentSafely(key)] = [existingValue, value]
            } else {
                params[decodeUriComponentSafely(key)] = existingValue.concat(value)
            }
        }
    })

    return params
}

/** excluding the `?` */
export const getQueryString = (url: string | null | undefined) => {
    if (!url || url.indexOf('?') === -1) {
        return undefined
    }

    return url.slice(url.indexOf('?') + 1)
}

export const decodeUriComponentSafely = (encodedUriComponent: string) => {
    try {
        return decodeURIComponent(encodedUriComponent)
    } catch {
        return encodedUriComponent
    }
}

export const concatPathWithQueryParams = (pathWithQuery: string, remainingQuery: { [key: string]: string | string[] | undefined }) => {
    const queryString = Object.entries(remainingQuery)
        .map(([key, values]) => {
            if (typeof values === 'undefined') {
                return undefined
            }
            const array = typeof values === 'string' ? [values] : values
            return array.map((value) => `${key}=${encodeURIComponent(value)}`).join('&')
        })
        .filter(Boolean)
        .join('&')
    if (queryString) {
        return pathWithQuery.indexOf('?') !== -1 ? `${pathWithQuery}&${queryString}` : `${pathWithQuery}?${queryString}`
    }
    return pathWithQuery
}

export const removeUrlParameter = (path: string, parameter: string) =>
    path
        .replace(new RegExp(`(?:(\\?)|&)${parameter}=[^&]*`, 'g'), '$1')
        .replace(/\?&/, '?')
        .replace(/\?$/, '')

export const removeUrlParameters = (path: string, parameters: string[]) => {
    return parameters.reduce(
        (url, parameter) =>
            (url = url
                .replace(new RegExp(`(?:(\\?)|&)${parameter}=[^&]*`, 'g'), '$1')
                .replace(/\?&/, '?')
                .replace(/\?$/, '')),
        path,
    )
}

export const getRelativeUrl = (absoluteUrl: string) => {
    try {
        const url = new URL(absoluteUrl)
        const relativeUrl = absoluteUrl
            .replace(new RegExp(`^${url.protocol}`), '')
            .replace(new RegExp(`^//`), '')
            .replace(new RegExp(`^${url.host}`), '')
            .replace(new RegExp(`^:${url.port}`), '')

        return relativeUrl || '/'
    } catch {
        return absoluteUrl
    }
}

/**
 * Checks strings whether they are absolute urls.
 * Doesn't allow local hosts or protocols other than http and https.
 * @param absoluteUrl the url to test
 * @returns true if absoluteUrl is a valid http or https url
 */
export const isValidUrl = (absoluteUrl: string) => {
    try {
        const url = new URL(absoluteUrl)
        if (!url.host.includes('.')) {
            return false
        }
    } catch (error) {
        return false
    }

    return absoluteUrl.startsWith('http://') || absoluteUrl.startsWith('https://')
}

export const removeQueryString = (url: string) => {
    return url.replace(/\?.*/, '')
}

const pathFromUrlRegex = /^[^?#]*/i

export const getPathFromUrl = (url: string): string => {
    if (url) {
        const matchResult = pathFromUrlRegex.exec(url)
        if (matchResult) {
            return matchResult[0]
        }
    }

    return url
}

export const isRelativeUrlWithSlashPrefix = (url: string): boolean => {
    if (url[0] !== '/') {
        return false
    }
    const regexExp = new RegExp('^(?:[a-z]+:)?//', 'i')
    return !regexExp.test(url)
}

// encode the uri only if it is not already encoded (fixes problems with & in keyword)
export const encodeURIifNecessary = (uri: string): string => {
    if (uri !== decodeURIComponent(uri)) {
        return uri
    } else {
        return encodeURI(uri)
    }
}

export const removeTrailingSlash = (url: string): string => (url.endsWith('/') ? url.substring(0, url.length - 1) : url)

export const removeHashParameter = (url: string): string => {
    const indexOfHash = url.lastIndexOf('#')
    return indexOfHash === -1 ? url : url.substring(0, indexOfHash)
}
