import { getVerticalList } from '@wh/common/chapter/api/verticalApiClient'
import {
    cleanUpProfileData,
    isUserLoggedIn,
    isUserLoggedOut,
    useProfileData,
    useSearchAgentCount,
    useUserStats,
    useVerticalsInfo,
} from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { commenceBackgroundAuthenticationFlow } from '@wh/common/authentication/backgroundAuthenticationService'
import { FunctionComponent, useEffect } from 'react'
import { isDynamicEnvironment, isLocal } from '@wh/common/chapter/lib/config/runtimeConfig'
import { getSearchAgentCount, getUserInfo, getUserStatistics } from '@wh/common/chapter/api/userApiClient'
import { getUniversalBbxCookie } from '../../types/cookies'
import { Request } from 'express'
import { NextRequest } from '../../types/nextJS'

/** This is needed as long as the app component is class based, we still need this for now as only
 * class based components can be error boundaries. This component is used to fetch userStats and userData client side  */
export const DataFetcher: FunctionComponent<{}> = () => {
    const [, setUserStats] = useUserStats()
    const [, setSearchAgentCount] = useSearchAgentCount()
    const [profileData, setProfileData] = useProfileData()
    const [, setVerticalsInfo] = useVerticalsInfo()

    useEffect(() => {
        const fetchAndSetData = async () => {
            try {
                if (profileData === 'unsure') {
                    const [verticalsInfo, userInfo] = await Promise.all([
                        getVerticalList().catch(() => undefined),
                        getUserInfo().catch(() => undefined),
                    ])
                    const newProfileData = userInfo ? cleanUpProfileData(userInfo) : undefined
                    setProfileData(newProfileData)
                    setVerticalsInfo(verticalsInfo?.vertical)
                }
            } catch (e) {}
        }

        fetchAndSetData()
        // we need to disable rule of hooks for this hook as we only want to run this once and not when profileData changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setProfileData, setVerticalsInfo])

    useEffect(() => {
        const tryAutoLogin = async () => {
            try {
                if (isUserLoggedOut(profileData) && shouldPerformBackgroundLogin()) {
                    await commenceBackgroundAuthenticationFlow({ redirectToLogin: false })
                    const newProfileData = await getUserInfo()
                    setProfileData(newProfileData ? cleanUpProfileData(newProfileData) : undefined)
                }
            } catch (e) {}
        }

        tryAutoLogin()
    }, [profileData, setProfileData])

    useEffect(() => {
        const fetchAndSetData = async () => {
            try {
                if (isUserLoggedIn(profileData)) {
                    const [userStats, searchAgentCount] = await Promise.all([getUserStatistics(), getSearchAgentCount()])
                    setUserStats(userStats)
                    setSearchAgentCount(searchAgentCount.activeCount)
                } else {
                    setUserStats(undefined)
                }
            } catch (e) {}
        }
        fetchAndSetData()
    }, [profileData, setUserStats, setSearchAgentCount])

    return null
}

export const shouldPerformBackgroundLogin = (request?: Request | undefined) => {
    return isAutoLoginCookieTrue(request) || isLocal() || isDynamicEnvironment()
}

export const isAutoLoginCookieTrue = (request?: NextRequest | undefined) => {
    const autoLoginCookie = getUniversalBbxCookie('WH_AUTO', request)
    return autoLoginCookie === 'true'
}
