import { TrendSliderType } from '@bbx/common/types/UserFeed'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { fetchFromContextLink } from '@bbx/search-journey/common/api/searchApiClient'
import { AdvertSummarySkeletons } from '@bbx/search-journey/common/components/AdvertSummary/AdvertSummarySkeleton'
import { BapAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/BapAdvertSummaryComponent'
import { TaggingWidgetType, useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { WidgetIcon } from '@bbx/search-journey/sub-domains/start-page/components/widget/FeedWidgetWrapper'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import FavoriteHeart from '@wh-components/icons/FavoriteHeart'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { HorizontalSlider } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { LazyPicture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import React, { FunctionComponent, RefObject, useContext, useEffect, useState } from 'react'
import { css } from 'styled-components'
import { useFeedEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'

export const TREND_SLIDER_ICON_BACKGROUND_COLOR = '#41bfc3'

export const TrendSlider: FunctionComponent<{
    trendSlider: TrendSliderType
    isInView: boolean
    titleWithIcon?: boolean
    source?: string
    page?: string
}> = ({ trendSlider, isInView, titleWithIcon = false, source = 'homepage', page = 'Startpage' }) => {
    const [searchResult, setSearchResult] = useState<SearchResult | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const nrOfTrendsShown = 8 // the teaser-image takes the space of one ad
    const taggingData = useFeedEmptyTaggingData()

    const advertisingState = useContext(AdvertisingStateContext)
    const color = advertisingState.pageModifications.foregroundColors?.['startpage-standard-color']
    const accentColor = advertisingState.pageModifications.foregroundColors?.['startpage-accent-color']

    const widgetAndPage = getWidgetAndPage(source, page, trendSlider.taggingId)
    const widgetAndPageForNormalClickTags = getWidgetAndPage(source, page)

    const { visibilityTrackingRef } = useWidgetTagging({
        ...widgetAndPage,
        adIds: searchResult?.advertSummaryList.advertSummary.map((ad) => ad.id).slice(0, nrOfTrendsShown),
        pulseMetadata: { listName: trendSlider.pulseMetadata.listName },
    })

    const webLink = findContextLinkWithIdFromArray('searchWebLink', trendSlider.contextLinkList)
    const searchLink = findContextLinkWithIdFromArray('searchApiLink', trendSlider.contextLinkList)

    useEffect(() => {
        async function loadSearchResult() {
            try {
                const result = await fetchFromContextLink<SearchResult>(searchLink!)
                setSearchResult(result)
            } catch (error) {
                // silently ignore
            } finally {
                setIsLoading(false)
            }
        }

        if (isInView && searchLink) {
            loadSearchResult()
        }
    }, [isInView, searchLink, trendSlider])

    if (!searchLink || !webLink) {
        return null
    }

    const onClickShowAll = async () => {
        callActionEvent('ad_widget_show_all_click', taggingData, {
            ...widgetAndPageForNormalClickTags,
            taggingId: trendSlider.taggingId,
        })
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
    }

    const onClickShowAllButton = async () => {
        callActionEvent('ad_widget_show_all_button_click', taggingData, {
            ...widgetAndPageForNormalClickTags,
            taggingId: trendSlider.taggingId,
        })
        callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
    }

    const advertSummaries = searchResult?.advertSummaryList.advertSummary.slice(0, nrOfTrendsShown).map((advertSummary, adIndex) => (
        <BapAdvertSummaryComponent
            key={advertSummary.id}
            advertSummary={advertSummary}
            onClickTaggingEvent={() => {
                callActionEvent('ad_widget_ad_click', taggingData, {
                    ...widgetAndPageForNormalClickTags,
                    taggingId: trendSlider.taggingId,
                    adIndex: adIndex + 1,
                    adId: advertSummary.id,
                    listName: trendSlider.pulseMetadata.listName,
                })
                callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
            }}
            variant="portrait"
            color={color}
            accentColor={accentColor}
        />
    ))

    if (!isLoading && !advertSummaries?.length) {
        return null
    }

    if (isLoading) {
        return <AdvertSummarySkeletons variant="portrait" />
    } else {
        return (
            <HorizontalSlider
                testIdPrefix="trends"
                href={webLink?.uri}
                linkText="Alle anzeigen"
                buttonText="Alle anzeigen"
                onClick={onClickShowAll}
                onClickButton={onClickShowAllButton}
                visibilityTrackingRef={visibilityTrackingRef}
                heading={
                    <TrendWidgetHeader
                        href={webLink?.uri}
                        title={trendSlider.title}
                        onClick={onClickShowAll}
                        titleWithIcon={titleWithIcon}
                    />
                }
                variant="portrait"
                subHeading={<Text lineClamp={2}>{trendSlider.subTitle}</Text>}
                teaser={
                    <Box minWidth={{ phone: '160px', desktop: '175px' }} width={{ phone: '160px', desktop: '175px' }} marginRight="s">
                        <ClientRoutingAnchorLink
                            width="100%"
                            height="100%"
                            type="anchor"
                            href={webLink?.uri}
                            display="block"
                            onClick={() => {
                                callActionEvent('startpage_trend_ad_slider_sujet_click', taggingData, {
                                    ...widgetAndPageForNormalClickTags,
                                    taggingId: trendSlider.taggingId,
                                })
                                callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                            }}
                        >
                            <LazyPicture
                                height="100%"
                                width="100%"
                                imgSrcSet={{
                                    x1: `${trendSlider.storyblokImage.url}/m/400x0/filters:quality(85)`,
                                    x2: `${trendSlider.storyblokImage.url}/m/800x0/filters:quality(85)`,
                                }}
                                alt={trendSlider.storyblokImage.alt}
                                css={css`
                                    object-fit: cover;
                                    border-radius: 0 0 ${(p) => p.theme.borderRadii.xl}px ${(p) => p.theme.borderRadii.xl}px;
                                    background-color: ${(p) => p.theme.colors.palette.babyseal};
                                `}
                            />
                        </ClientRoutingAnchorLink>
                    </Box>
                }
            >
                {advertSummaries?.map((advertSummary) => advertSummary) ?? []}
            </HorizontalSlider>
        )
    }
}

const TrendWidgetHeader: FunctionComponent<{
    title: string
    href: string
    visibilityTrackingRef?: RefObject<HTMLDivElement>
    onClick?: () => void
    titleWithIcon: boolean
}> = ({ title, href, visibilityTrackingRef, onClick, titleWithIcon }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const titleColor = advertisingState.pageModifications.foregroundColors?.['startpage-title-color']

    return (
        <Box display="flex" alignItems="center">
            {titleWithIcon && <WidgetIcon icon={FavoriteHeart} backgroundColor={TREND_SLIDER_ICON_BACKGROUND_COLOR} />}

            <ClientRoutingAnchorLink type="anchor" href={href} onClick={onClick} color={titleColor ?? 'palette.verydarkgrey'}>
                <Text
                    lineClamp={1}
                    fontWeight="bold"
                    fontSize={titleWithIcon ? 'l' : undefined}
                    width="100%"
                    ref={visibilityTrackingRef}
                    css={css`
                        cursor: pointer;

                        &:active,
                        &:hover {
                            text-decoration: underline;
                        }
                    `}
                >
                    {title}
                </Text>
            </ClientRoutingAnchorLink>
        </Box>
    )
}

const getWidgetAndPage = (
    source: string,
    page: string,
    taggingId?: string,
): { taggingWidgetType: TaggingWidgetType; source: string; page: string } => ({
    taggingWidgetType: taggingId ? `TrendSlider_${taggingId}` : 'TrendSlider',
    source: source,
    page: page,
})
