/* eslint-disable max-lines */

import { Avatar } from '@wh-components/core/Avatar/Avatar'
import { Badge } from '@wh-components/core/Badge/Badge'
import { Box } from '@wh-components/core/Box/Box'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { Text } from '@wh-components/core/Text/Text'
import { theme } from '@wh-components/core/theme'
import FavoriteIcon from '@wh-components/icons/Favorite'
import MessagingIcon from '@wh-components/icons/Messaging'
import TenantIcon from '@wh-components/icons/Tenant'
import NewIcon from '@wh-components/icons/CatNewconstruction'
import MyAdsIcon from '@wh-components/icons/Myads'
import PinIcon from '@wh-components/icons/Pin'
import ProfileIcon from '@wh-components/icons/Profile'
import PersonalizationIcon from '@wh-components/icons/Personalization'
import QuestionCircleIcon from '@wh-components/icons/QuestionCircle'
import SearchagentIcon from '@wh-components/icons/Searchagent'
import SearchHistoryIcon from '@wh-components/icons/Searchhistory'
import { EnterBirthYearContext } from '@wh/common/chapter/components/EnterBirthYearProvider/EnterBirthYearProvider'
import {
    isUserLoggedIn,
    useProfileData,
    useSearchAgentCount,
    useUnreadMessagesCount,
    useUserStats,
} from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { SearchAgentLinks } from '@wh/common/chapter/components/Header/SearchAgentLinks'
import { ssoLogoutLink } from '@wh/common/chapter/lib/config/runtimeConfig'
import { resetShouldShowEnterBirthYearVisitCount } from '@wh/common/chapter/lib/incrementShouldShowEnterBirthYearVisitCount'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingActionEvent } from '@wh/common/chapter/lib/tagging/taggingTypes'
import classNames from 'classnames'
import dayjs from 'dayjs'
import React, { Fragment, ReactElement, useContext } from 'react'
import { css } from 'styled-components'
import { StyledAnchorLink, VerticalMenuStyle } from './VerticalMenuStyle'
import { findContextLinkWithId } from '../../types/contextLinks'
import { DAC7Notification } from '../DAC7/DAC7Notification'
import { requiresDac7Action } from '../../lib/userHints'
import PayLiveryIcon from '@wh-components/icons/Paylivery'
import { useFeatureToggle } from '../FeatureToggleProvider/FeatureToggleProvider'
import { MyAdsLinks } from './MyAdsLinks'

type VerticalMenuPosition = 'sidemenu' | 'header'

export type AllowedMenuItems =
    | 'chat'
    | 'myadverts'
    | 'mysearches'
    | 'myfindings'
    | 'edituser'
    | 'mytransactions'
    | 'edittenant'
    | 'mylastsearches'
    | 'personalization'
    | 'help'

interface VerticalMenuItem {
    id: AllowedMenuItems
    text: string
    icon: ReactElement
    url: string
    clientSideRouting: boolean
    color: string
    tagging: {
        sidemenu: TaggingActionEvent | undefined
        header: TaggingActionEvent | undefined
    }
}

const verticalMenuItems: VerticalMenuItem[] = [
    {
        id: 'myadverts',
        text: 'Meine Anzeigen',
        icon: <MyAdsIcon size="small" marginRight="s" />,
        url: '/iad/myprofile/myadverts',
        clientSideRouting: true,
        color: theme.colors.palette.accent.primary.main,
        tagging: {
            sidemenu: undefined,
            header: 'header_usermenu_click_myAdverts',
        },
    },
    {
        id: 'mytransactions',
        text: 'Meine Transaktionen',
        icon: <PayLiveryIcon size="small" marginRight="s" />,
        url: '/iad/myprofile/mytransactions/seller',
        clientSideRouting: true,
        color: theme.colors.palette.lavender,
        tagging: {
            sidemenu: undefined,
            header: 'header_usermenu_click_mytransactions',
        },
    },
    {
        id: 'myfindings',
        text: 'Merkliste',
        icon: <FavoriteIcon size="small" marginRight="s" />,
        url: '/iad/myprofile/myfindings',
        clientSideRouting: true,
        color: theme.colors.palette.petrol,
        tagging: {
            sidemenu: undefined,
            header: 'header_usermenu_click_myFindings',
        },
    },
    {
        id: 'mysearches',
        text: 'Suchagent',
        icon: <SearchagentIcon size="small" marginRight="s" />,
        url: '/iad/myprofile/mysearches',
        clientSideRouting: true,
        color: theme.colors.palette.accent.secondary.main,
        tagging: {
            sidemenu: undefined,
            header: 'header_usermenu_click_mySearches',
        },
    },
    {
        id: 'mylastsearches',
        text: 'Suchverlauf',
        icon: <SearchHistoryIcon size="small" marginRight="s" />,
        url: '/iad/myprofile/mylastsearches',
        clientSideRouting: true,
        color: theme.colors.palette.jungle,
        tagging: {
            sidemenu: undefined,
            header: 'header_usermenu_click_myLastSearches',
        },
    },
    {
        id: 'chat',
        text: 'Nachrichten',
        icon: <MessagingIcon size="small" marginRight="s" />,
        url: '/iad/myprofile/chat',
        clientSideRouting: true,
        color: theme.colors.palette.primary.main,
        tagging: {
            sidemenu: undefined,
            header: 'header_usermenu_click_chat',
        },
    },
    {
        id: 'edittenant',
        text: 'Mietprofil',
        icon: <TenantIcon size="small" marginRight="s" />,
        url: '/iad/myprofile/edittenant',
        clientSideRouting: true,
        color: theme.colors.palette.primary.main,
        tagging: {
            sidemenu: 'mywillhaben_edittenant',
            header: 'mywillhaben_edittenant',
        },
    },
    {
        id: 'personalization',
        text: 'Personalisierung',
        icon: <PersonalizationIcon size="small" marginRight="s" />,
        url: '/iad/myprofile/personalization',
        clientSideRouting: true,
        color: theme.colors.palette.special.rose,
        tagging: {
            sidemenu: undefined,
            header: 'header_usermenu_click_personalization',
        },
    },
    {
        id: 'edituser',
        text: 'Profildaten',
        icon: <ProfileIcon size="small" marginRight="s" />,
        url: '/iad/myprofile/edituser',
        clientSideRouting: true,
        color: theme.colors.palette.elephant,
        tagging: {
            sidemenu: undefined,
            header: 'header_usermenu_click_editUser',
        },
    },
    {
        id: 'help',
        text: 'Hilfe & Kontakt',
        icon: <QuestionCircleIcon size="small" marginRight="s" />,
        url: 'https://hilfe.willhaben.at/hc/de',
        clientSideRouting: false,
        color: theme.colors.palette.elephant,
        tagging: {
            sidemenu: undefined,
            header: 'header_usermenu_click_help',
        },
    },
]

interface VerticalMenuProps {
    selectedItem?: AllowedMenuItems
    showUserData?: boolean
    menuPosition: VerticalMenuPosition
    savedAdsLists?: React.ReactNode
}

export const VerticalMenu = ({ selectedItem, savedAdsLists, showUserData = true, menuPosition }: VerticalMenuProps) => {
    const verticalMenuHeaderClasses = classNames({
        'wh-vertical-menu--header': true,
        'wh-vertical-menu--header--nouserdata': !showUserData,
    })

    const verticalMenuFooterClasses = classNames({
        'wh-vertical-menu--footer': true,
        'wh-vertical-menu--footer--nouserdata': !showUserData,
    })

    const [profileData] = useProfileData()

    return (
        <div className="wh-vertical-menu" css={VerticalMenuStyle}>
            <div className={verticalMenuHeaderClasses}>
                {showUserData && (
                    <Avatar
                        src={isUserLoggedIn(profileData) ? profileData.picture : undefined}
                        alt="Profilbild"
                        size="large"
                        marginTop={-56}
                        marginBottom="s"
                    />
                )}
                <Text fontSize={showUserData ? 'l' : 'm'} fontWeight="bold">
                    Mein willhaben
                </Text>
            </div>
            <div>
                <VerticalMenuItems selectedItem={selectedItem} savedAdsLists={savedAdsLists} menuPosition={menuPosition} />
            </div>
            <div className={verticalMenuFooterClasses}>
                {showUserData && isUserLoggedIn(profileData) && (
                    <Box paddingBottom="s" display="flex" flexDirection="column" alignItems="center">
                        {profileData.addressPostcode && (
                            <Text fontSize="s">
                                <PinIcon size="xsmall" marginLeft="-4px" marginBottom="-2px" />
                                {profileData.addressPostcode} {profileData.address3}
                            </Text>
                        )}
                        <Text fontSize="s">User:in seit {dayjs(profileData.created).format('MM/YYYY')}</Text>
                    </Box>
                )}
                <ButtonWithLinkStyle
                    onClick={() => {
                        window.location.assign(ssoLogoutLink)
                        resetShouldShowEnterBirthYearVisitCount()
                        return callActionEvent('header_usermenu_click_logout', undefined)
                    }}
                    testId="verticalMenu-logout"
                    color="palette.elephant"
                    fontSize="s"
                >
                    Ausloggen
                </ButtonWithLinkStyle>
            </div>
        </div>
    )
}

const VerticalMenuItems = ({
    selectedItem,
    savedAdsLists,
    menuPosition,
}: Pick<VerticalMenuProps, 'selectedItem' | 'savedAdsLists' | 'menuPosition'>) => {
    const [unreadMessages] = useUnreadMessagesCount()
    const [userStats] = useUserStats()
    const [searchAgentCount] = useSearchAgentCount()
    const [profileData] = useProfileData()
    const { shouldShowEnterBirthYear } = useContext(EnterBirthYearContext)
    const showEditTenant =
        !!findContextLinkWithId('editTenantProfileUri', (profileData === 'unsure' ? undefined : profileData)?.contextLinkList) &&
        !(profileData === 'unsure' ? undefined : profileData)?.isAdminUser
    const showMyJobAds =
        !!findContextLinkWithId('jobsMyAdvertsUri', (profileData === 'unsure' ? undefined : profileData)?.contextLinkList) &&
        !(profileData === 'unsure' ? undefined : profileData)?.isAdminUser

    const showNotificationDot = isUserLoggedIn(profileData) && (shouldShowEnterBirthYear || requiresDac7Action(profileData))
    const myTransactionsToggle = useFeatureToggle('enableMyTransactionsPage')
    let menuItems = verticalMenuItems
    if (!myTransactionsToggle) {
        menuItems = menuItems.filter((item) => item.id !== 'mytransactions')
    }

    const showPersonalization = useFeatureToggle('enableSizePersonalization')
    return (
        <Fragment>
            {menuItems.map((verticalMenuItem: VerticalMenuItem) => {
                if (verticalMenuItem.id === 'edittenant' && !showEditTenant) {
                    return
                }
                if (verticalMenuItem.id === 'personalization' && !showPersonalization) {
                    return
                }

                const selected = selectedItem === verticalMenuItem.id

                let counter = -1
                if (verticalMenuItem.id === 'myadverts') {
                    counter = userStats ? userStats.adsCount : -1
                } else if (verticalMenuItem.id === 'mysearches') {
                    counter = searchAgentCount
                } else if (verticalMenuItem.id === 'myfindings') {
                    counter = userStats ? userStats.folderAdsCount : -1
                }

                const iconColor = selected || menuPosition === 'header' ? verticalMenuItem.color : theme.colors.palette.secondary.main

                return (
                    <Box key={verticalMenuItem.id} width="100%" height="100%">
                        <StyledAnchorLink
                            type="anchor"
                            clientSideRouting={verticalMenuItem.clientSideRouting}
                            className="wh-vertical-menu--item"
                            testId={`verticalMenuItem-${verticalMenuItem.id}`}
                            href={verticalMenuItem.url}
                            iconColor={iconColor}
                            iconColorHover={verticalMenuItem.color}
                            selected={selected}
                            onClick={() => {
                                const tag = verticalMenuItem.tagging[menuPosition]

                                if (typeof tag !== 'undefined') {
                                    return callActionEvent(tag, undefined)
                                }

                                return
                            }}
                        >
                            {verticalMenuItem.icon}
                            <Box display="flex" alignItems="baseline" className="wh-vertical-menu--item-text">
                                <Box marginRight="s">
                                    <Text className="wh-vertical-menu--item-text-title" fontWeight={selected ? 'bold' : undefined}>
                                        {verticalMenuItem.text}
                                    </Text>
                                </Box>
                                {verticalMenuItem.id === 'chat' && unreadMessages > 0 && (
                                    <Box
                                        alignSelf="center"
                                        css={css`
                                            /* gets rid of extra padding at the top */
                                            line-height: 1;
                                        `}
                                    >
                                        <Badge color1="palette.coral" lineHeight="1" paddingHorizontal="6px" paddingVertical="3px">
                                            {unreadMessages > 99 ? '99+' : unreadMessages.toString()}
                                        </Badge>
                                    </Box>
                                )}

                                {counter > 0 && (
                                    <Text fontSize="s" color="palette.elephant">
                                        {counter}
                                    </Text>
                                )}
                            </Box>

                            {verticalMenuItem.id === 'edituser' && showNotificationDot && (
                                <Box>
                                    {profileData && requiresDac7Action(profileData) ? (
                                        <DAC7Notification dac7Attempt={profileData.dac7NotificationAttempt} />
                                    ) : (
                                        <Badge
                                            border-radius="100%"
                                            padding="0px"
                                            color1="palette.coral"
                                            lineHeight="1"
                                            css={css`
                                                height: 9px;
                                                width: 9px;
                                            `}
                                        >
                                            {/* Badge expects children, but we just want to show a dot without content */}
                                            {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                                            {''}
                                        </Badge>
                                    )}
                                </Box>
                            )}

                            {(verticalMenuItem.id === 'edittenant' || verticalMenuItem.id === 'personalization') && (
                                <NewIcon
                                    size="small"
                                    marginLeft="m"
                                    css={css`
                                        fill: ${(p) => p.theme.colors.palette.accent.secondary.main};
                                    `}
                                />
                            )}
                        </StyledAnchorLink>

                        {showMyJobAds &&
                            verticalMenuItem.id === 'myadverts' &&
                            (selectedItem === 'myadverts' || menuPosition === 'header') && (
                                <MyAdsLinks isHighlighted={menuPosition !== 'header'} />
                            )}
                        {verticalMenuItem.id === 'mysearches' && (selectedItem === 'mysearches' || menuPosition === 'header') && (
                            <SearchAgentLinks alertsCount={searchAgentCount} isHighlighted={menuPosition !== 'header'} />
                        )}
                        {verticalMenuItem.id === 'myfindings' && selectedItem === 'myfindings' && <Fragment>{savedAdsLists}</Fragment>}
                    </Box>
                )
            })}
        </Fragment>
    )
}
