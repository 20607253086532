import { SbReactComponentsMap } from '@storyblok/react'
import { StoryblokStaticPage } from '../components/pagetypes/StoryblokStaticPage'
import { StoryblokRichText } from '../components/bloks/StoryblokRichText'
import React from 'react'
import { StoryblokAnchorHeading } from '../components/bloks/StoryblokAnchorHeading'
import { StoryblokTableOfContent } from '../components/bloks/StoryblokTableOfContent'
import { StoryblokOpenDidomiLink } from '../components/bloks/StoryblokOpenDidomiLink'
import { StoryblokTable } from '../components/bloks/StoryblokTable'
import { StoryblokTabs } from '../components/bloks/StoryblokTabs'
import { StoryblokRatioColumn } from '../components/bloks/StoryblokRatioColumn'
import { StoryblokCard } from '../components/bloks/StoryblokCard'
import { StoryblokFactTable } from '../components/bloks/StoryblokFactTable'
import { StoryblokTitleCard } from '../components/bloks/StoryblokTitleCard'
import { StoryblokContact } from '../components/bloks/StoryblokContact'
import { StoryblokBlogList } from '../components/bloks/StoryblokBlogList'
import { StoryblokColumns } from '../components/bloks/StoryblokColumns'
import { StoryblokAssetDownload } from '../components/bloks/StoryblokAssetDownload'
import { StoryblokBox } from '../components/bloks/StoryblokBox'
import { StoryblokNegativeHorizontalMarginMobileBox } from '../components/bloks/StoryblokNegativeHorizontalMarginMobileBox'
import { StoryblokTextWithPictureAndLinks } from '../components/bloks/StoryblokTextWithPictureAndLinks'
import { StoryblokTextWithPicture } from '../components/bloks/StoryBlokTextWithPicture'
import { StoryblokTextWithPictureList } from '../components/bloks/StoryblokTextWithPictureList'
import { StoryblokTextWithPictureAndLinksList } from '../components/bloks/StoryblokTextWithPictureAndLinksList'
import { StoryblokTitleCardBlue } from '../components/bloks/StoryblokInfoCardBlue'
import { StoryblokHintLink } from '../components/bloks/StoryblokHintLink'
import { StoryblokImageBlok } from '../components/bloks/StoryblokImageBlok'
import { StoryblokHeaderLinkImage } from '../components/bloks/StoryblokHeaderLinkImage'
import { StoryblokTextWithPictureFullWidth } from '../components/bloks/StoryblokTextWithPictureFullWidth'
import { StoryblokButton } from '../components/bloks/StoryblokButton'
import { StoryblokBlueBox } from '../components/bloks/StoryblokBlueBox'
import { StoryblokDivider } from '../components/bloks/StoryblokDivider'
import { StoryblokYoutube } from '../components/bloks/StoryblokYoutube'
import { StoryblokIframe } from '../components/bloks/StoryblokIframe'
import { StoryblokAppScreenshotCard } from '../components/bloks/StoryblokAppScreenshotCard'
import { StoryblokBubbleList } from '../components/bloks/StoryblokBubbleList'
import { StoryblokPictureWithBlueTextBottom } from '../components/bloks/StoryblokPictureWithBlueTextBottom'
import { StoryblokBackgroundImageBox } from '../components/bloks/StoryblokBackgroundImageBox'
import { StoryblokBlogpostFeaturedRow } from '../components/bloks/StoryblokBlogpostFeaturedRow'
import { StoryblokFeaturedBlogpostItem } from '../components/bloks/StoryblokFeaturedBlogpostItem'
import { StoryblokBlogpost } from '../components/pagetypes/StoryblokBlogpost'
import { StoryblokBlogpostAdditionalEntries } from '../components/bloks/StoryblokBlogpostPostAdditionalEntries'
import { StoryblokCarAuctionsSellerWidget } from '../components/bloks/StoryblokCarAuctionsSellerWidget'
import { StoryblokCarAuctionsBacklink } from '../components/bloks/StoryblokCarAuctionsBacklink'
import { StoryblokCarAuctionsBenefitsList } from '../components/bloks/StoryblokCarAuctionsBenefitsList'
import { StoryblokCarAuctionsBenefitsItem } from '../components/bloks/StoryblokCarAuctionsBenefitsItem'
import { StoryblokCarAuctionsFaqList } from '../components/bloks/StoryblokCarAuctionsFaqList'
import { StoryblokCollapsibleFaq } from '../components/bloks/StoryblokCollapsibleFaq'
import { StoryblokHotjarIntegration } from '../components/bloks/StoryblokHotjarIntegration'
import { StoryblokUserzoomIntegration } from '../components/bloks/StoryblokUserzoomIntegration'

export const storyBlokPageTypes: SbReactComponentsMap = {
    article: StoryblokStaticPage,
    blogpost: StoryblokBlogpost,
}

export const storyBlokComponents: SbReactComponentsMap = {
    richText: StoryblokRichText,
    anchorHeading: StoryblokAnchorHeading,
    tableOfContents: StoryblokTableOfContent,
    openDidomiLink: StoryblokOpenDidomiLink,
    table: StoryblokTable,
    tabs: StoryblokTabs,
    ratioColumn: StoryblokRatioColumn,
    card: StoryblokCard,
    factTable: StoryblokFactTable,
    titleCard: StoryblokTitleCard,
    contact: StoryblokContact,
    blogList: StoryblokBlogList,
    columns: StoryblokColumns,
    assetDownload: StoryblokAssetDownload,
    box: StoryblokBox,
    negativeHorizontalMarginMobileBox: StoryblokNegativeHorizontalMarginMobileBox,
    textWithPicture: StoryblokTextWithPicture,
    textWithPictureList: StoryblokTextWithPictureList,
    textWithPictureAndLinks: StoryblokTextWithPictureAndLinks,
    textWithPictureAndLinksList: StoryblokTextWithPictureAndLinksList,
    hintLink: StoryblokHintLink,
    image: StoryblokImageBlok,
    titleCardBlue: StoryblokTitleCardBlue,
    headerLinkImage: StoryblokHeaderLinkImage,
    button: StoryblokButton,
    blueBox: StoryblokBlueBox,
    divider: StoryblokDivider,
    textWithPictureFullWidth: StoryblokTextWithPictureFullWidth,
    youtube: StoryblokYoutube,
    iFrame: StoryblokIframe,
    appScreenshotCard: StoryblokAppScreenshotCard,
    bubbleList: StoryblokBubbleList,
    pictureWithBlueTextBottom: StoryblokPictureWithBlueTextBottom,
    backgroundImageBox: StoryblokBackgroundImageBox,
    blogpostFeaturedRow: StoryblokBlogpostFeaturedRow,
    blogpostFeatured: StoryblokFeaturedBlogpostItem,
    blogpostPostAdditionalEntries: StoryblokBlogpostAdditionalEntries,
    carAuctionsSellerWidget: StoryblokCarAuctionsSellerWidget,
    carAuctionsBacklink: StoryblokCarAuctionsBacklink,
    carAuctionsBenefitsList: StoryblokCarAuctionsBenefitsList,
    carAuctionsBenefitsItem: StoryblokCarAuctionsBenefitsItem,
    carAuctionsFaqList: StoryblokCarAuctionsFaqList,
    collapsibleFaq: StoryblokCollapsibleFaq,
    hotjarIntegration: StoryblokHotjarIntegration,
    userzoomIntegration: StoryblokUserzoomIntegration,
}

export const richTextComponents = Object.keys(storyBlokComponents).reduce(
    (result: { [key: string]: (props: Record<string, unknown>) => JSX.Element | null }, key) => {
        const value = storyBlokComponents[key]
        if (value) {
            result[key] = (props) => React.createElement(value, { blok: props as unknown as React.ComponentProps<typeof value> })
        }
        return result
    },
    {},
)
