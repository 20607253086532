import React, { FunctionComponent, PropsWithChildren } from 'react'
import { getQueryParam } from '@wh/common/chapter/lib/urlHelpers'

type EnableDisable = 'enable' | 'disable'

export type DebugFlags = {
    advertisingMode: EnableDisable
    trackingMode: EnableDisable
    cmpMode: EnableDisable
    optimizelyMode: EnableDisable
    userzoomMode: EnableDisable
    hotjarMode: EnableDisable
}

export const DebugFlagContext = React.createContext<DebugFlags>({
    advertisingMode: 'enable',
    trackingMode: 'enable',
    cmpMode: 'enable',
    optimizelyMode: 'enable',
    userzoomMode: 'enable',
    hotjarMode: 'enable',
})

export const DebugFlagProvider: FunctionComponent<PropsWithChildren<{ url: string }>> = (props) => {
    const { advertisingMode, trackingMode, cmpMode, optimizelyMode, userzoomMode, hotjarMode } = getDebugFlags(props.url)

    return (
        <DebugFlagContext.Provider value={{ advertisingMode, trackingMode, cmpMode, optimizelyMode, userzoomMode, hotjarMode }}>
            {props.children}
        </DebugFlagContext.Provider>
    )
}

export const getDebugFlags = (url: string) => {
    let advertisingMode: EnableDisable = getQueryParam(url, 'debugAdvertisingMode') === 'disable' ? 'disable' : 'enable'
    let trackingMode: EnableDisable = getQueryParam(url, 'debugTrackingMode') === 'disable' ? 'disable' : 'enable'
    let cmpMode: EnableDisable = getQueryParam(url, 'debugCMPMode') === 'disable' ? 'disable' : 'enable'
    let optimizelyMode: EnableDisable = getQueryParam(url, 'debugOptimizelyMode') === 'disable' ? 'disable' : 'enable'
    let userzoomMode: EnableDisable = getQueryParam(url, 'debugUserzoomMode') === 'disable' ? 'disable' : 'enable'
    let hotjarMode: EnableDisable = getQueryParam(url, 'debugHotjarMode') === 'disable' ? 'disable' : 'enable'

    if (getQueryParam(url, 'debugDisableAll3rdPartyScripts') === 'true') {
        advertisingMode = trackingMode = cmpMode = optimizelyMode = userzoomMode = hotjarMode = 'disable'
    }

    return { advertisingMode, trackingMode, cmpMode, optimizelyMode, userzoomMode, hotjarMode }
}
