import { Request } from 'express'
import { BBX_APPLICATION, RequestWithToggles } from '@wh/common/chapter/types/nextJS'
import { getFeatureToggles } from '../../api/togglesApiClient'
import { IncomingMessage } from 'http'

/**
 * On SSR, we can access Unleash and its feature toggles.
 * We can only import from 'unleash-client' in server.ts, because it's not isomorphic and crashes in React Components.
 * Therefore we grep all feature-toggles in server.ts and append it the request-object passed to getInitialProps.
 * This functions then is used in _app.tsx to populate the FeatureToggleProvider,
 * that makes it possible to read the toggles in all React Components with the useFeatureToggle()-hook
 *
 * On Client-Side, we can't access unleash - but we can read the serialized feature-toggles from __NEXT_DATA__, to
 * be able to populate our FeatureToggleProvider
 * This seems a little bit hacky, but the Redux-integration (next-redux-wrapper) does more or less the same thing - it also takes the Redux-store on the Client out of __NEXT_DATA__
 */

export const getFeatureTogglesFromCtx = async (
    app: BBX_APPLICATION,
    req: Request | IncomingMessage | undefined,
): Promise<Record<string, boolean>> => {
    if (req) {
        return (req as RequestWithToggles).toggles
    } else {
        const props = window?.__NEXT_DATA__?.props as NEXT_DATA_PROPS

        const toggles = props?.initialProps?.toggles
        if (toggles === undefined) {
            const fetchedToggles = await getFeatureToggles(app)
            try {
                props.initialProps!.toggles = fetchedToggles
            } catch (ignored) {}
            return fetchedToggles
        } else {
            return toggles ?? {}
        }
    }
}

interface NEXT_DATA_PROPS {
    initialProps?: { toggles?: Record<string, boolean> }
}
