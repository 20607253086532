import { redirectToLoginPage } from '@wh/common/chapter/lib/routing/bbxRouter'

interface AutoLoginData {
    isUserAuthenticated: boolean
    isAutoLoginMessage: boolean
}

export const commenceBackgroundAuthenticationFlow = (options: { redirectToLogin: boolean }) => {
    return new Promise((resolve, reject) => {
        const authenticationFrame = createBackgroundAuthenticationFrame()

        const authenticationFlowListener = (e: MessageEvent<AutoLoginData>) => {
            const data = e.data
            const origin = e.origin

            if (!data.isAutoLoginMessage) {
                return
            }

            if (!/^http(s)?:\/\/(((www[^.]*|bbx[^.]*\.dynamic-envs)\.willhaben\.at)|(localhost:[0-9]*))([/?#].*)?$/.exec(origin)) {
                return
            }

            if (startsWith(origin, window.location.origin)) {
                if (data.isUserAuthenticated) {
                    try {
                        window.removeEventListener('message', authenticationFlowListener)
                        document.body.removeChild(authenticationFrame)
                    } catch (ignored) {}

                    resolve(void 0)
                } else {
                    options.redirectToLogin && redirectToLoginPage()

                    reject()
                }
            }
        }

        window.addEventListener('message', authenticationFlowListener)
        document.body.appendChild(authenticationFrame)
    })
}

const startsWith = (string: string, subString: string) => {
    return string.lastIndexOf(subString, 0) === 0
}

const createBackgroundAuthenticationFrame = (): HTMLIFrameElement => {
    const id = 'authenticationFrame'
    const oldAuthenticationFrame = document.getElementById(id)

    oldAuthenticationFrame?.parentElement?.removeChild(oldAuthenticationFrame)

    const authenticationFrame: HTMLIFrameElement = document.createElement('iframe')

    authenticationFrame.id = id
    authenticationFrame.src = '/webapi/sso/login?background-login'
    authenticationFrame.width = '0'
    authenticationFrame.height = '0'
    authenticationFrame.style.border = '0'

    return authenticationFrame
}
