import { theme } from '@wh-components/core/theme'

type StatusColorKey = keyof typeof theme.colors.adStatus

/** Returns the color key for the given adStatusId */
export const colorKeyForAdStatus = (adStatusId: number | undefined): StatusColorKey => {
    const status = splitStatusId(adStatusId)

    if (typeof status === 'undefined') {
        return 'inactive'
    }

    const { statusMain, statusSub } = status

    if (statusMain === 'ACTIVE' && statusSub === 'NONE') {
        return 'active'
    } else if (statusMain === 'REJECTED' || statusMain === 'SOFTREJECT') {
        return 'rejected'
    } else if (statusMain === 'EXPIRED' || statusMain === 'ARCHIVED' || statusMain === 'SOLD') {
        return 'expired'
    } else {
        return 'inactive'
    }
}

export type AdStatusMain =
    | 'NEW'
    | 'PAYMENTPENDING'
    | 'WAITINGCONTROL'
    | 'READY'
    | 'ACTIVE'
    | 'EXPIRED'
    | 'ARCHIVED'
    | 'REJECTED'
    | 'FOLLOWUP'
    | 'PREVIEW'
    | 'SOFTREJECT'
    | 'SOLD'

export type AdStatusSub =
    | 'NONE'
    | 'BLOCKED'
    | 'DEACTIVATED'
    | 'BLOCKEDDEACTIVATED'
    | 'DELETED'
    | 'DELETEDBLOCKED'
    | 'DELETEDDEACTIVATED'
    | 'DELETEDBLOCKEDDEACTIVATED'

export const adStatusMainMap: Record<number, AdStatusMain | undefined> = {
    1: 'NEW', // Neu
    2: 'PAYMENTPENDING', // Zahlung erwartet
    3: 'WAITINGCONTROL', // In Warteschlange
    4: 'READY', // Fertig
    5: 'ACTIVE', // Aktiv
    6: 'EXPIRED', // Abgelaufen
    7: 'ARCHIVED', // Archiviert
    8: 'REJECTED', // Abgelehnt
    9: 'FOLLOWUP', // Nachkontrolle
    10: 'PREVIEW', // Vorabansicht
    11: 'SOFTREJECT', // Bitte nachbessern
    12: 'SOLD', // Verkauft
}

export const adStatusSubMap: Record<number, AdStatusSub | undefined> = {
    0: 'NONE', // no sub status
    1: 'BLOCKED', // gesperrt
    2: 'DEACTIVATED', // deaktiviert
    3: 'BLOCKEDDEACTIVATED', // gesperrt/deaktiviert
    4: 'DELETED', // geloescht
    5: 'DELETEDBLOCKED', // geloescht/gesperrt
    6: 'DELETEDDEACTIVATED', // geloescht/deaktiviert
    7: 'DELETEDBLOCKEDDEACTIVATED', // geloescht/gesperrt/deaktiviert
}

export const adStatusFlag = {
    SOLD: 'sold',
    PASSIVE: 'passive',
    ACTIVE: 'active',
    EXPIRED: 'expired',
    DEACTIVATED: 'deactivated',
    DELETED: 'deleted',
    RENTED: 'rented',
    GIVEN_AWAY: 'given_away',
    RESERVED: 'reserved',
}

export const splitStatusId = (adStatusId: number | undefined): { statusMain: AdStatusMain; statusSub: AdStatusSub } | undefined => {
    if (!adStatusId) {
        return undefined
    }

    const statusIdMain = Math.floor(adStatusId / 10)
    const statusIdSub = adStatusId % 10

    const statusMain = adStatusMainMap[statusIdMain]
    const statusSub = adStatusSubMap[statusIdSub]

    if (typeof statusMain === 'undefined' || typeof statusSub === 'undefined') {
        return undefined
    }

    return {
        statusMain,
        statusSub,
    }
}
